import React from 'react';

import { bool, func, string } from 'prop-types';
import styles from './styles.module.scss';
import { AUDI_PRIVACY_DISCLAMER, AUDI_PRIVACY_POLICY_URL, AUDI_TERMS_URL } from './common';

const Disclaimer = ({ make, isConfirmed, setIsConfirmed }) => {
  if (make !== 'audi') {
    return null;
  }

  return (
    <div className={styles.container}>
      <span className={styles.inputLabel}>
        {AUDI_PRIVACY_DISCLAMER}
        <a href={AUDI_PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        .
      </span>
      <div className={styles.checkbox}>
        <input
          type="checkbox"
          checked={isConfirmed}
          onChange={() => setIsConfirmed(prev => !prev)}
        />
        I understand and agree to the
        <a href={AUDI_PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
          Privacy Statement
        </a>
        and
        <a href={AUDI_TERMS_URL} target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
      </div>
    </div>
  );
};

Disclaimer.propTypes = {
  make: string,
  isConfirmed: bool.isRequired,
  setIsConfirmed: func.isRequired,
};

Disclaimer.defaultProps = {
  make: '',
};

export default Disclaimer;
