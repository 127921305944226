import React, { Component } from 'react';
import cx from 'classnames';
import { func, string, bool } from 'prop-types';
import { connect } from 'react-redux';

import { extractPhoneNumberFromString } from 'shared/utils';
import {
  phoneNumberFormatValidator,
  phoneNumberLengthValidator,
} from 'shared/validators';
import { submitCustomerIdentification } from 'actions/booking/chat-actions';
import { appDealershipDmsTypeSelector } from 'selectors/booking/app-selectors';

import Button from 'components/common/Button';
import sendIcon from 'assets/images/send.svg';

import styles from './styles.module.scss';

class WelcomeStepInput extends Component {
  state = {
    phoneNumber: '',
    valid: true,
    disabled: false,
  };

  handleChange = event => this.setState({ phoneNumber: event.target.value });

  handleSubmit = (event) => {
    event.preventDefault();
    const numberString = this.state.phoneNumber;
    let phoneNumber = null;
    if (phoneNumberFormatValidator(numberString)) {
      phoneNumber = extractPhoneNumberFromString(numberString);
    }
    if (
      phoneNumber &&
      phoneNumberLengthValidator(phoneNumber, this.props.dmsType)
    ) {
      this.setState({ valid: true, disabled: true });
      this.props.onSubmit({ phoneNumber });
    } else {
      this.setState({ valid: false });
    }
  };

  render() {
    const { isStandAlone } = this.props;
    if (this.state.disabled) {
      return null;
    }

    return (
      <form className={styles.container} onSubmit={this.handleSubmit}>
        <div className={styles.inputContainer}>
          <input
            autoFocus
            className={cx(styles.input, {
              [styles.invalid]: !this.state.valid,
              [styles.isStandAlone]: isStandAlone,
            })}
            value={this.state.phoneNumber}
            onChange={this.handleChange}
          />
          {!isStandAlone && (
            <button className={styles.submit} onClick={this.handleSubmit}>
              <img alt="send icon" className={styles.icon} src={sendIcon} />
            </button>
          )}
        </div>
        {!this.state.valid && (
          <span className={styles.error}>
            Please provide a valid phone number.
          </span>
        )}
        {isStandAlone && (
          <Button
            disabled={this.props.disabled}
            className={styles.inputButton}
            caption="Confirm"
            onClick={this.handleSubmit}
            isWide
          />
        )}
      </form>
    );
  }
}

WelcomeStepInput.propTypes = {
  onSubmit: func.isRequired,
  dmsType: string.isRequired,
  disabled: bool.isRequired,
  isStandAlone: bool,
};

WelcomeStepInput.defaultProps = {
  isStandAlone: false,
};

const mapStateToProps = state => ({
  dmsType: appDealershipDmsTypeSelector(state),
});

const actions = {
  onSubmit: submitCustomerIdentification,
};

const WelcomeStepInputContainer = connect(
  mapStateToProps,
  actions,
)(WelcomeStepInput);

export default WelcomeStepInputContainer;
