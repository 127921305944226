export const appCableChannelKeySelector = state => state.app.actionCableChannelKey;
export const appDealershipIdSelector = state => state?.app?.dealership?.id;
export const appBookingIdSelector = state => state.app.bookingId;
export const appDealershipTimeZoneSelector = state => state.app.dealership.timeZone;
export const appDealershipNameSelector = state => state.app.dealership.name;
export const appDealershipTaxRateSelector = state => state.app.dealership.taxRate;
export const appDealershipDmsTypeSelector = state => state?.app?.dealership?.dmsType;
export const appDealershipPhoneSelector = state => state.app.dealership?.phone;
export const appDealershipDealerCodeSelector = state => state.app.dealership.dealerCode;
export const appDealershipDefaultMakeSelector = state => state.app.dealership.defaultMake;
export const appFlatModeSelector = state => state.app.flatMode;
export const appEmbedWidgetOpenSelector = state => state.app.embedWidgetOpen;
export const appServicesPriceVisibleSelector = state => state.app.dealership.servicesPriceVisible;
export const appWidgetAvatarEnabledSelector = state => state?.app?.dealership?.widgetAvatarEnabled;
export const appPhantomAdvisorAvailable = state => state.app.dealership.phantomAdvisorAvailable;
export const appPickupEnabled = state => state.app.dealership.pickupEnabled;
export const appMobileTechnicianEnabled = state => state.app.dealership.mobileTechnicianEnabled;
export const appMobileTechnicianSegments = state => state.app.dealership.mobileTechnicianSegments;
export const appDealershipPosition = state => state.app.dealership.dealershipPosition;
export const appMobileTechnicianTimeVariance = state => (
  state.app.dealership.mobileTechnicianTimeVariance
);
export const appPickupTimeVariance = state => state.app.dealership.pickupTimeVariance;
export const appPickupNoteForCustomers = state => state.app.dealership.pickupNoteForCustomers;
export const appPickupSegments = state => state.app.dealership.pickupSegments;
export const appTeamTagsSelector = state => state.app.teamTags;
export const appLocalizationForCustomersEnabled = state => (
  state.app.dealership?.localizationForCustomersEnabled
);
